/* eslint class-methods-use-this: 1 */
/* eslint-env es6 */

import angular from 'angular';
import _get from 'lodash/get';
import sha1 from 'sha1';
import _uniqBy from 'lodash/uniqBy';
import _map from 'lodash/map';

import fmChangePasswordTemplate from './change-password.pug';

class ApplicationCtrl {
  constructor(
    $sce,
    $timeout,
    $window,
    $rootScope,
    AuthService,
    AuthEvents,
    $templateCache,
    alertify,
    Messages,
    Account,
    GpsRole,
    Config,
  ) {
    'ngInject';

    this.AuthService = AuthService;
    this.alertify = alertify;
    this.Messages = Messages;
    this.Account = Account;
    this.GpsRole = GpsRole;
    this.$window = $window;
    this.menuRedirect = false;

    $templateCache.put('change-password', fmChangePasswordTemplate);
    $rootScope.$on(
      AuthEvents.currentUserUpdated,
      async (event, currentUser) => {
        this.menu = [];
        this.currentUser = { ...currentUser };
        this.user = this.currentUser;
        const menu = [
          // {
          //   state: 'policeAddress',
          //   permission: ['Admin'],
          //   title: 'Địa chỉ tác chiến',
          // },
          {
            state: 'transport',
            permission: ['User'],
            title: 'Phương tiện',
          },
          {
            state: 'transportType',
            permission: ['SupperAdmin'],
            title: 'Loại phương tiện',
          },
          {
            state: 'devices',
            permission: ['Admin'],
            title: 'Thiết bị',
          },
          {
            state: 'driver',
            permission: ['User'],
            title: 'Quản lý/ Người điều khiển',
          },
          {
            state: 'stationType',
            permission: ['SupperAdmin'],
            title: 'Loại trạm',
          },
          {
            state: 'customer',
            permission: ['User'],
            title: 'Khách hàng',
          },
          {
            state: 'suppliers',
            permission: ['SupperAdmin'],
            title: 'Nhà cung cấp',
          },
          {
            state: 'accounts',
            permission: ['SupperAdmin'],
            children: [
              {
                state: 'accounts',
                children: [],
                title: 'Quản lý người dùng',
              },
              {
                state: 'role',
                children: [],
                title: 'Quản lý nhóm',
              },
            ],
            title: 'Hệ thống',
          },
          // {
          //   state: 'activeDevice',
          //   permission: ['Accountant'],
          //   title: 'Kích hoạt thiết bị',
          // },
          {
            state: 'sim',
            permission: ['Admin'],
            title: 'Quản lý SIM',
          },
          {
            state: 'sim-logs',
            permission: ['User'],
            title: 'Quản lý Log Sim',
          },
          {
            state: 'eventRealtime',
            permission: ['User', 'Combat'],
            title: 'Quản lý Event time',
          },
          {
            state: 'feedback',
            permission: ['User', 'Combat'],
            title: 'Feedback',
          },
          {
            state: 'costtoserve',
            permission: ['Fee'],
            title: 'Quản lý cước',
          },
          {
            state: 'history',
            permission: ['User'],
            title: 'Quản lý tọa độ',
          },
          {
            state: 'feemanagement',
            permission: ['Accountant'],
            title: 'Quản lý cước',
          },
        ];
        if (!currentUser.roles) {
          const data = await this.GpsRole.getRoles().$promise;
          menu.forEach((item1) => {
            item1.permission.forEach((item2) => {
              data.roles.forEach((item) => {
                if (item === item2) {
                  this.menu.push(item1);
                }
              });
            });
          });
          this.menu = _uniqBy(_map(this.menu));
        } else {
          menu.forEach((item1) => {
            item1.permission.forEach((item2) => {
              currentUser.roles.forEach((item) => {
                if (item === item2) {
                  this.menu.push(item1);
                }
              });
            });
          });
          this.menu = _uniqBy(_map(this.menu));
        }
        const transportId =
          sessionStorage.getItem('$LoopBack$transportId') ||
          sessionStorage.getItem('$LoopBack$customerId');
        const redirect = sessionStorage.getItem('$LoopBack$redirect');
        if (transportId && redirect) {
          this.menuRedirect = true;
        }
      },
    );

    const listKey = Config.get('googleMapAPIKey');
    const key = listKey[Math.floor(Math.random() * listKey.length)];
    this.googleMapApi = $sce.trustAsResourceUrl(
      [
        'https://maps.googleapis.com/maps/api/js?key=',
        key,
        '&libraries=places',
      ].join(''),
    );

    const onresize = () => {
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      $timeout(() => {
        this.size = {
          width,
          height,
          bodyHeight: height - 150,
          gridHeight: Math.max(height - 150, 500),
        };
      });
    };
    window.onresize = onresize;
    onresize();
  }

  setCurrentUser(currentUser) {
    this.currentUser = currentUser;
  }

  logout() {
    this.AuthService.logout();
    // this.$window.location.reload();
    sessionStorage.removeItem('$LoopBack$customerId');
    sessionStorage.removeItem('$LoopBack$redirect');
    sessionStorage.removeItem('$LoopBack$transportId');
  }

  isAuthenticated() {
    return this.AuthService.isAuthenticated();
  }

  async getUserData() {
    const account = await this.Account.getPass().$promise;
    return account;
  }

  changePassword() {
    this.getUserData().then((data) => {
      if (
        data.password[0].password ===
        sha1(this.objPass.oldPassword).toUpperCase()
      ) {
        this.user.password = this.objPass.newPassword;
        this.Account.prototype$patchAttributes(this.user)
          .$promise.then(() => {
            this.alertify.success(this.Messages.changePasswordSuccess);
            this.objPass = {};
            if (this.modalPass) {
              this.modalPass.hide();
            }
            this.logout();
          })
          .catch((res) => {
            const errCode = _get(res, 'data.error.code');
            let message = this.Messages.changePasswordFailed;
            if (errCode in this.Messages) {
              message = `${message} ${this.Messages[errCode]}`;
            }
            this.alertify.error(message);
          });
      } else {
        this.alertify.error('Change Password Failed');
      }
    });
  }
}

export default angular
  .module('app.application.controller', [])
  .controller('ApplicationCtrl', ApplicationCtrl);
