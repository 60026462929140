import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider.state('role', {
    url: '/role',
    templateProvider: () =>
      import(/* webpackChunkName: "role" */ './role.pug').then(
        ({ default: tpl }) => tpl,
      ),
    controller: 'roleCtrl as vm',
    requiredPermission: 'role',
    resolve: {
      loadCtrl: $ocLazyLoad =>
        Promise.all([
          import(/* webpackChunkName: "role" */ './role.controller'),
          import('../../extensions/init/ag-grid'),
          import('../../extensions/directives/select2'),
        ]).then(([module]) => {
          $ocLazyLoad.load({ name: module.name });
          return module.controller;
        }),
      account: Account => Account.find().$promise,
    },
  });
}

export default angular.module('app.role.routing', []).config(routes);
