import angular from 'angular';

export default angular.module('app.routing', [
  require('./components/login/login.routing').name,
  require('./components/police-address/police-address.routing').name,
  require('./components/suppliers/suppliers.routing').name,
  require('./components/devices/devices.routing').name,
  require('./components/transport/transport.routing').name,
  require('./components/driver/driver.routing').name,
  require('./components/stationType/stationType.routing').name,
  require('./components/transportType/transportType.routing').name,
  require('./components/customer/customer.routing').name,
  require('./components/account/account.routing').name,
  require('./components/role/role.routing').name,
  require('./components/accountPolice/accountPolice.routing').name,
  require('./components/feedback/feedback.routing').name,
  require('./components/sim/sim.routing').name,
  require('./components/monitor-journey/monitor-journey.routing').name,
  require('./components/sim-tel/sim-tel.routing').name,
  require('./components/event-realtime/event-realtime.routing').name,
  require('./components/gpsHistory/gpsHistory.routing').name,
  require('./components/feemanagement/feemanagement.routing').name,
  require('./components/sim-logs/sim-logs.routing').name,
  // require('./components/activeDevice/activeDevice.routing').name,
])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/404');
    $stateProvider.state('default', {
      url: '/404',
      template: '404',
      onEnter($transition$, $state, AuthService) {
        $transition$.abort();
        AuthService.getCurrentUser().then((currentUser) => {
          if (!currentUser) {
            return $state.go('login');
          }

          return $state.go(AuthService.getStage());
        });
      },
    });
  });

