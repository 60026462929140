import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider.state('accounts', {
    url: '/accounts',
    templateProvider: () =>
      import(/* webpackChunkName: "account" */ './account.pug').then(
        ({ default: tpl }) => tpl,
      ),
    controller: 'accountCtrl as vm',
    requiredPermission: 'account',
    resolve: {
      loadCtrl: $ocLazyLoad =>
        Promise.all([
          import(/* webpackChunkName: "account" */ './account.controller'),
          import('../../extensions/init/ag-grid'),
          import('../../extensions/directives/select2'),
        ]).then(([module]) => {
          $ocLazyLoad.load({ name: module.name });
          return module.controller;
        }),
      user: User =>
        User.find({
          filter: {
            fields: {
              id: true,
              username: true,
            },
          },
        }).$promise,
    },
  });
}

export default angular.module('app.account.routing', []).config(routes);
