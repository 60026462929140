import angular from 'angular';

function routes($stateProvider, $urlRouterProvider) {
  'ngInject';

  // Redirect to `/monitor` when `/monitor-journey` is accessed
  $urlRouterProvider.when('/monitor-journey', '/monitor-journey/monitor');

  // Helper function to get parameters from sessionStorage
  function getParams() {
    'ngInject';

    const result = {};
    if (sessionStorage.getItem('$LoopBack$transportId')) {
      result.transportId = sessionStorage.getItem('$LoopBack$transportId');
    } else if (sessionStorage.getItem('$LoopBack$customerId')) {
      result.customerId = sessionStorage.getItem('$LoopBack$customerId');
    }
    return result;
  }

  // Helper function to determine limit
  function getLimit() {
    'ngInject';

    let result = '';
    if (sessionStorage.getItem('$LoopBack$transportId')) {
      result = 1;
    } else if (sessionStorage.getItem('$LoopBack$customerId')) {
      result = '';
    }
    return result;
  }

  // Define application states
  $stateProvider
    .state('monitorJourney', {
      url: '/monitor-journey',
      abstract: true, // Abstract state to be used as a parent state
    })
    .state('monitorJourney.monitor', {
      url: '/monitor', // Changed URL to avoid conflict with 'journey'
      templateProvider: () =>
        import(/* webpackChunkName: "monitor-journey" */ './monitor-journey.pug').then(
          ({ default: tpl }) => tpl,
        ),
      controller: 'monitorJourneyCtrl as vm',
      requiredPermission: 'view',
      resolve: {
        loadCtrl: $ocLazyLoad =>
          Promise.all([
            import(/* webpackChunkName: "monitor-journey" */ './monitor-journey.controller'),
            import('../../extensions/directives/select2'),
            import('../../extensions/directives/date-range-picker'),
            import('../../extensions/directives/rome'),
            import('../../extensions/directives/validator-date'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
        $emitter: () => import('eventemitter3').then(({ EventEmitter }) => new EventEmitter()),
        params: getParams,
        limit: getLimit,
        gpsRealtimeV: GpsRealtimeV =>
          GpsRealtimeV.find({
            filter: {
              order: 'localName ASC',
              limit: getLimit(),
              where: getParams(),
              fields: {
                transportId: true,
                brand: true,
                certificateNumber: true,
                course: true,
                driver: true,
                duration: true,
                effectEndDate: true,
                eventTime: true,
                fuelLevel: true,
                fuelTotal: true,
                GPLX: true,
                GPLXExpire: true,
                latitude: true,
                localName: true,
                longitude: true,
                speed: true,
                statusCode: true,
                stopNum: true,
                totalKm: true,
                transportMaintenance: true,
                transportType: true,
                vinNo: true,
                dayRuntime: true,
                transportOwner: true,
              },
            },
          }).$promise,
      },
    })
    .state('monitorJourney.journey', {
      url: '/journey',
      templateProvider: () =>
        import(/* webpackChunkName: "monitor-journey" */ './monitor-journey.pug').then(
          ({ default: tpl }) => tpl,
        ),
      controller: 'monitorJourneyCtrl as vm',
      requiredPermission: 'view',
      resolve: {
        loadCtrl: $ocLazyLoad =>
          Promise.all([
            import(/* webpackChunkName: "monitor-journey" */ './monitor-journey.controller'),
            import('../../extensions/directives/select2'),
            import('../../extensions/directives/date-range-picker'),
            import('../../extensions/directives/rome'),
            import('../../extensions/directives/validator-date'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
        $emitter: () => import('eventemitter3').then(({ EventEmitter }) => new EventEmitter()),
        params: getParams,
        limit: getLimit,
        gpsRealtimeV: GpsRealtimeV =>
          GpsRealtimeV.find({
            filter: {
              order: 'localName ASC',
              limit: getLimit(),
              where: getParams(),
              fields: {
                transportId: true,
                brand: true,
                certificateNumber: true,
                course: true,
                driver: true,
                duration: true,
                effectEndDate: true,
                eventTime: true,
                fuelLevel: true,
                fuelTotal: true,
                GPLX: true,
                GPLXExpire: true,
                latitude: true,
                localName: true,
                longitude: true,
                speed: true,
                statusCode: true,
                stopNum: true,
                totalKm: true,
                transportMaintenance: true,
                transportType: true,
                vinNo: true,
                dayRuntime: true,
                transportOwner: true,
              },
            },
          }).$promise,
      },
    });
}

export default angular.module('app.monitorJourney.routing', []).config(routes);
