import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider.state('customer', {
    url: '/customer',
    templateProvider: () =>
      import(/* webpackChunkName: "customer" */ './customer.pug').then(
        ({ default: tpl }) => tpl,
      ),
    controller: 'customerCtrl as vm',
    requiredPermission: 'customer',
    resolve: {
      loadCtrl: $ocLazyLoad =>
        Promise.all([
          import(/* webpackChunkName: "customer" */ './customer.controller'),
          import('../../extensions/init/ag-grid'),
          import('../../extensions/directives/select2'),
          import('../../extensions/directives/date-range-picker'),
          import('../../extensions/directives/rome'),
          import('../../extensions/directives/validator-date'),
        ]).then(([module]) => {
          $ocLazyLoad.load({ name: module.name });
          return module.controller;
        }),
      gpsCustomerType: GpsCustomerType => GpsCustomerType.find().$promise,
      gpsProvinceType: GpsProvinceType => GpsProvinceType.find().$promise,
      cbanAccountPoliceV: CbanAccountPoliceV => CbanAccountPoliceV.find({ filter: { where: { parent: 0 } } }).$promise,
    },
  });
}

export default angular.module('app.customer.routing', []).config(routes);
