import angular from 'angular';
import _get from 'lodash/get';

class AuthInterceptor {
  constructor($rootScope, AuthEvents, LoopBackAuth) {
    'ngInject';

    this.responseError = (response) => {
      switch (response.status) {
        case 401:
          {
            const errorCode = _get(response, 'data.error.code');
            if (errorCode === 'AUTHORIZATION_REQUIRED') {
              $rootScope.$broadcast(AuthEvents.notAuthorized, response);
            } else {
              LoopBackAuth.clearUser();
              LoopBackAuth.clearStorage();
              $rootScope.$broadcast(AuthEvents.notAuthenticated, response);
            }
          }
          break;
        case 403:
          $rootScope.$broadcast(AuthEvents.notAuthorized, response);
          break;
        case 419:
        case 440:
          LoopBackAuth.clearUser();
          LoopBackAuth.clearStorage();
          $rootScope.$broadcast(AuthEvents.sessionTimeout, response);
          break;
        default:
          break;
      }
      return Promise.reject(response);
    };
  }

  static factory($rootScope, AuthEvents, LoopBackAuth) {
    'ngInject';

    AuthInterceptor.instance = new AuthInterceptor(
      $rootScope,
      AuthEvents,
      LoopBackAuth,
    );
    return AuthInterceptor.instance;
  }
}

export default angular
  .module('app.factory.auth-interceptor', [])
  .factory('AuthInterceptor', AuthInterceptor.factory)
  .config(($httpProvider) => {
    'ngInject';

    $httpProvider.interceptors.push('AuthInterceptor');
  });
