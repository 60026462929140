import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider.state('transport', {
    url: '/transport',
    templateProvider: () =>
      import(/* webpackChunkName: "transport" */ './transport.pug').then(
        ({ default: tpl }) => tpl,
      ),
    controller: 'transportCtrl as vm',
    requiredPermission: 'transport',
    resolve: {
      loadCtrl: $ocLazyLoad =>
        Promise.all([
          import(/* webpackChunkName: "transport" */ './transport.controller'),
          import('../../extensions/init/ag-grid'),
          import('../../extensions/directives/select2'),
          import('../../extensions/directives/date-range-picker'),
          import('../../extensions/directives/rome'),
          import('../../extensions/directives/validator-date'),
        ]).then(([module]) => {
          $ocLazyLoad.load({ name: module.name });
          return module.controller;
        }),
      gpsTransportType: GpsTransportType =>
        GpsTransportType.find({
          filter: { fields: { id: true, localName: true } },
        }).$promise,
      gpsProvinceType: GpsProvinceType =>
        GpsProvinceType.find({
          filter: { fields: { id: true, localName: true } },
        }).$promise,
      // gpsAddressDistrictV: GpsAddressDistrictV =>
      //   GpsAddressDistrictV.find({
      //     filter: { fields: { id: true, name: true } },
      //   }).$promise,
      gpsCustomer: GpsCustomer =>
        GpsCustomer.find({
          filter: { fields: { id: true, username: true } },
        }).$promise,
      gpsDrivers: GpsDrivers =>
        GpsDrivers.find({
          filter: { fields: { id: true, driverName: true, driverLicenseId: true } },
        }).$promise,
      // gpsDevices: GpsDevices => GpsDevices.getDeviceDistribute().$promise,
    },
  });
}

export default angular.module('app.transport.routing', []).config(routes);
