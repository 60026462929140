import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider.state('sim', {
    url: '/sim',
    templateProvider: () =>
      import(/* webpackChunkName: "sim" */ './sim.pug').then(
        ({ default: tpl }) => tpl,
      ),
    controller: 'devicesCtrl as vm',
    requiredPermission: 'sim',
    resolve: {
      loadCtrl: $ocLazyLoad =>
        Promise.all([
          import(/* webpackChunkName: "sim" */ './sim.controller'),
          import('../../extensions/init/ag-grid'),
          import('../../extensions/directives/select2'),
        ]).then(([module]) => {
          $ocLazyLoad.load({ name: module.name });
          return module.controller;
        }),
      account: Account => Account.find({
        filter: {
          fields: { id: true, username: true },
        },
      }).$promise.then(),
    },
  });
}

export default angular.module('app.sim.routing', []).config(routes);
