import angular from 'angular';
import config from 'assets/../lib/config.json';

import _get from 'lodash/get';

const configGetter = {
  get: key => _get(config, key),
};

function ConfigProvider() {
  this.$get = () => configGetter;
}

export default angular
  .module('app.factory.config', [])
  .provider('Config', ConfigProvider)
  .factory('Config', () => configGetter);
