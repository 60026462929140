import angular from 'angular';
import permissionMapping from 'assets/../lib/permission-mapping.json';

class AuthService {
  constructor($rootScope, AuthEvents, User) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.AuthEvents = AuthEvents;
    this.User = User;

    this._roles = [];
    $rootScope.$on(AuthEvents.currentUserUpdated, (_, currentUser) => {
      this._user = currentUser;
      if (currentUser) {
        this._roles = currentUser.roles;
      } else {
        this._roles = [];
      }
    });
  }

  login(credentials, rememberMe = false) {
    return this.User.login({ rememberMe }, credentials)
      .$promise.then((session) => {
        this.$rootScope.$broadcast(this.AuthEvents.loginSuccess, session);
        this.$rootScope.$broadcast(
          this.AuthEvents.currentUserUpdated,
          session.user,
        );
        return session.user;
      })
      .catch((err) => {
        this.$rootScope.$broadcast(this.AuthEvents.loginFailed, err);
        throw err;
      });
  }

  logout() {
    return this.User.logout().$promise.then(() => {
      this.$rootScope.$broadcast(this.AuthEvents.logoutSuccess);
    });
  }

  isAuthenticated() {
    return this.User.isAuthenticated();
  }

  isInRole(role) {
    return this._roles.indexOf(role) > -1;
  }

  getStage = () => {
    let stage = 'policeAddress';
    this._roles.forEach((item) => {
      if (item === 'User') {
        stage = 'transport';
      } else if (item === 'Combat') {
        stage = 'eventRealtime';
      } else if (item === 'SupperAdmin') {
        stage = 'accounts';
      } else if (item === 'Admin') {
        stage = 'devices';
      } else if (item === 'Accountant') {
        stage = 'feemanagement';
      } else if (item === 'view') {
        stage = 'monitorJourney';
      }
    });
    return stage;
  };


  isAuthorized(action) {
    if (!(action in permissionMapping)) return false;
    let i = 0;
    while (i < permissionMapping[action].length) {
      if (this._roles.indexOf(permissionMapping[action][i]) > -1) {
        return true;
      }
      i += 1;
    }
  }

  async getCurrentUser() {
    if (!this.isAuthenticated()) return Promise.resolve(false);
    const currentUser = this.User.getCachedCurrent();
    if (currentUser) return Promise.resolve(currentUser);

    const redirect = sessionStorage.getItem('$LoopBack$redirect');
    return this.User.getCurrent().$promise.then((user) => {
      if (user) {
        if (redirect) {
          user.roles = ['view'];
          this.$rootScope.$broadcast(this.AuthEvents.currentUserUpdated, user);
        } else {
          this.$rootScope.$broadcast(this.AuthEvents.currentUserUpdated, user);
        }
      }
      return user;
    });
  }


  setCurrentUser(user) {
    this.LoopBackAuth.currentUserData = user;
    this.$rootScope.$broadcast(this.AuthEvents.currentUserUpdated, user);
  }

  static factory($rootScope, AuthEvents, User, GpsRole) {
    'ngInject';

    AuthService.instance = new AuthService(
      $rootScope,
      AuthEvents,
      User,
      GpsRole,
    );
    return AuthService.instance;
  }
}

export default angular
  .module('app.factory.auth-service', [])
  .factory('AuthService', AuthService.factory);
