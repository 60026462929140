import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider.state('accountPolices', {
    url: '/accountPolices',
    templateProvider: () =>
      import(/* webpackChunkName: "accountPolice" */ './accountPolice.pug').then(
        ({ default: tpl }) => tpl,
      ),
    controller: 'accountPoliceCtrl as vm',
    requiredPermission: 'accountPolice',
    resolve: {
      loadCtrl: $ocLazyLoad =>
        Promise.all([
          import(/* webpackChunkName: "accountPolice" */ './accountPolice.controller'),
          import('../../extensions/init/ag-grid'),
          import('../../extensions/directives/select2'),
        ]).then(([module]) => {
          $ocLazyLoad.load({ name: module.name });
          return module.controller;
        }),
    },
  });
}

export default angular.module('app.accountPolice.routing', []).config(routes);
