import mainNavTemplate from './main-nav.pug';
import footerTemplate from './footer.pug';
import './layout.less';

export default function ($templateCache) {
  'ngInject';

  $templateCache.put('layout/main-nav', mainNavTemplate);
  $templateCache.put('layout/footer', footerTemplate);
}
