import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider.state('driver', {
    url: '/driver',
    templateProvider: () =>
      import(/* webpackChunkName: "driver" */ './driver.pug').then(
        ({ default: tpl }) => tpl,
      ),
    controller: 'driverCtrl as vm',
    requiredPermission: 'driver',
    resolve: {
      loadCtrl: $ocLazyLoad =>
        Promise.all([
          import(/* webpackChunkName: "driver" */ './driver.controller'),
          import('../../extensions/init/ag-grid'),
          import('../../extensions/directives/select2'),
          import('../../extensions/directives/date-range-picker'),
          import('../../extensions/directives/rome'),
          import('../../extensions/directives/validator-date'),
        ]).then(([module]) => {
          $ocLazyLoad.load({ name: module.name });
          return module.controller;
        }),
    },
  });
}

export default angular.module('app.driver.routing', []).config(routes);
