export default function (
  $trace,
  $state,
  $transitions,
  $rootScope,
  AuthEvents,
  AuthService,
  alertify,
  Messages,
) {
  'ngInject';

  // Enable TRANSITION trace
  // $trace.enable('TRANSITION');

  $transitions.onStart({}, async (trans) => {
    const next = trans._targetState._definition;
    const authenticated = await AuthService.getCurrentUser();

    if (next.name === 'login' && !authenticated) {
      return;
    }

    if (next.name === 'login' && authenticated) {
      return trans.router.stateService.target('default');
    }

    if (!authenticated) {
      return trans.router.stateService.target('login');
    }
    if (!next.self.requiredPermission) return;
    const authorized = await AuthService.isAuthorized(
      next.self.requiredPermission,
    );

    if (!authorized) {
      alertify.error(Messages.permissionDenied);
      return Promise.reject('Not Authorized');
    }
  });

  const login = () => {
    $state.go('login');
  };

  $rootScope.$on(AuthEvents.loginSuccess, () => {
    $state.go('default');
  });
  $rootScope.$on(AuthEvents.loginFailed, () => {});
  $rootScope.$on(AuthEvents.logoutSuccess, login);
  $rootScope.$on(AuthEvents.notAuthenticated, login);
  $rootScope.$on(AuthEvents.notAuthorized, () => {
    alertify.error(Messages.notAuthorized);
  });
  $rootScope.$on(AuthEvents.sessionTimeout, login);
}
