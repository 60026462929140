import angular from 'angular';

function routes($stateProvider) {
  'ngInject';

  $stateProvider.state('feemanagement', {
    url: '/feemanagement',
    templateProvider: () =>
      import(/* webpackChunkName: "feemanagement" */ './feemanagement.pug').then(
        ({ default: tpl }) => tpl,
      ),
    controller: 'feemanagementCtrl as vm',
    requiredPermission: 'feemanagement',
    resolve: {
      loadCtrl: $ocLazyLoad =>
        Promise.all([
          import(/* webpackChunkName: "feemanagement" */ './feemanagement.controller'),
          import('../../extensions/init/ag-grid'),
          import('../../extensions/directives/select2'),
          import('../../extensions/directives/date-range-picker'),
          import('../../extensions/directives/rome'),
          import('../../extensions/directives/validator-date'),
        ]).then(([module]) => {
          $ocLazyLoad.load({ name: module.name });
          return module.controller;
        }),
      gpsTransportType: GpsTransportType =>
        GpsTransportType.find({
          filter: { fields: { id: true, localName: true } },
        }).$promise,
      gpsProvinceType: GpsProvinceType =>
        GpsProvinceType.find({
          filter: { fields: { id: true, localName: true } },
        }).$promise,
      gpsDrivers: GpsDrivers =>
        GpsDrivers.find({
          filter: { fields: { id: true, driverName: true, driverLicenseId: true } },
        }).$promise,
    },
  });
}

export default angular.module('app.feemanagement.routing', []).config(routes);
